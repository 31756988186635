<template>
  <div>
    <div class="modal fade order-placed" id="orderplaced" tabindex="-1" aria-labelledby="orderplacedLabel"
      aria-hidden="true" ref="PlaceOrderModal" style="z-index: 9999;">

      <!-- <div class="modal fade order-placed" id="AlertModalPlaced" tabindex="-1" aria-labelledby="AlertModalLabel" aria-hidden="true" ref="AlertModal" style="z-index: 9999;"> -->
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" v-if="iswallet == '0'">
          <div class="modal-body text-center">
            <img src="@/assets/images/confirmed-icn.svg" />

            <p class="mt-3 mb-1" v-if="subscription_type">
              {{ $t('Your subscription has been activated. You can manage your subscription by from Profile >
              Subscriptions.You will receive updates regarding your subscription orders once they are placed.') }}

            </p>
            <p class="mt-3 mb-1" v-else>
              {{ $t('Your order has been placed') }}.
              <br />{{ $t('You can track order status on orders tab') }}.
            </p>
          </div>
          <div class="modal-footer full-btn d-block" v-if="subscription_type">
            <!-- <router-link to="/" data-bs-dismiss="modal">{{ $t('Home') }}</router-link> -->
            <button @click="goHome" data-bs-dismiss="modal">{{ $t('Home') }}</button>
          </div>
          <div class="modal-footer full-btn d-block" v-else>
            <a href="javascript:void(0);" data-bs-dismiss="modal">{{ $t('Track Order') }}</a>
          </div>
        </div>
        <div class="modal-content" v-if="iswallet == '1'">
          <div class="modal-body text-center">
            <img src="@/assets/images/confirmed-icn.svg" />
            <p class="mt-3 mb-1">
              {{ $t('Your recharge has been successfully') }}.
            </p>
          </div>
          <div class="modal-footer full-btn d-block">
            <a href="javascript:void(0);" data-bs-dismiss="modal">{{ $t('Ok') }}</a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  data() {
    return {
      orderDetails: ''
    }
  },
  props: ['orderId', 'iswallet', "subscription_type"],

  beforeDestroy() {
    // Remove event listener when the component is destroyed
    window.removeEventListener("popstate", this.handleBackButton);
  },

  mounted() {
    // if (this.subscription_type) {
    //   this.goHome()
    // } else {
    this.orderDetails = JSON.parse(localStorage.getItem('subscriptionObj'))
    $(this.$refs.PlaceOrderModal).on("hidden.bs.modal", this.oncloseModle);
    window.$("#orderplaced").on('show.bs.modal', function () { });
    window.$("#orderplaced").modal("show");
    window.$(this.$refs.PlaceOrderModal).modal();
    $(this.$refs.PlaceOrderModal).on("hidden.bs.modal", this.oncloseModle);

    history.pushState(null, null, location.href);
    // Listen for the back button press
    window.addEventListener("popstate", this.handleBackButton);
    // }
  },
  methods: {
    ...mapMutations("product", ["clearCartItems",]),
    ...mapMutations("order", ["clearPlaceOrderData"]),

    handleBackButton() {
      // Close the modal when the back button is pressed
      window.$(this.$refs.PlaceOrderModal).modal("hide");
    },

    goHome() {
      this.$router.push('/');
    },

    oncloseModle() {
      if (this.subscription_type) {
        this.goHome()
      } else {
        if (this.iswallet == '0') {
          this.$store.commit("product/clearCartItems");
          this.$store.commit("order/clearPlaceOrderData");
          localStorage.setItem('orderUploadImages', '');
          this.$router.push({ name: "order-status", params: { order_id: this.base64_encode(this.orderId) } });
        } else {
          this.$router.push({ name: "wallet" });
        }
      }

    }
  }
};
</script>